import React from 'react';
import Layout from 'src/layout/Layout';
import seoPage from '../../content/seo/404.yml';
import Intro from 'src/components/Intro/Intro';
import { IntroCaption, IntroMessage } from 'src/components/Intro/Intro.dump';
import { OldRedButton } from 'src/components/buttons/Button.dump';

const NotFoundPage: React.FC = () => {
	return (
		<Layout seoPage={seoPage} theme="white">
			<Intro theme="white">
				<IntroCaption>404</IntroCaption>
				<IntroMessage>
					<h2>Page not found</h2>
					<p>
						The Page you are looking for doesn’t exist or an other occurred.
						<br />
						Go back, or head overto main page to choose a new directions
					</p>
					<OldRedButton css={'margin-top: 35px;'} href="/">
						Main page
					</OldRedButton>
				</IntroMessage>
			</Intro>
		</Layout>
	);
};

export default NotFoundPage;
